<template>
  <div>
    <b-list-group v-if="hersNorway.length > 0">
      <b-list-group-item
        v-for="(item, index) in content"
        :key="'form-element-herNorway-' + index"
      >
        <b-row v-if="unusedHersNorway.length > 0">
          <b-col>
            {{ $t("components.herNorwayWithNotes.toggle") }}
            <b-form-checkbox v-model="usedToggles[index]" switch class="d-inline">
              {{
                usedToggles[index]
                  ? $t("components.herNorwayWithNotes.used")
                  : $t("components.herNorwayWithNotes.unused")
              }}
            </b-form-checkbox>
          </b-col>
        </b-row>
        <multiselect
          :id="'form-element-herNorway-' + index"
          v-model="item.herNorway.id"
          :options="
            usedToggles[index] || unusedHersNorway.length == 0
              ? hersNorway.map(i => i.id)
              : unusedHersNorway.map(i => i.id)"
          :custom-label="key => herLabels[key]"
          :show-labels="false"
          @select="changed"
        />
        <b-list-group>
          <b-list-group-item
            v-for="(herNote, hnindex) in item.herNorwayNotes"
            :key="
              'form-element-herNorway' + index + 'herNorwayNotes-' + hnindex
            "
          >
            <b-row>
              <b-col>
                <b-form-input
                  :id="'herNorway-' + index + 'notes-input-' + hnindex"
                  v-model="herNote['notes']"
                  @change="changed"
                  @input="changed"
                />
              </b-col>
              <b-col sm="auto">
                <multiselect
                  v-if="languages.length > 0"
                  v-model="herNote.lang.id"
                  :options="languages.map(i => i.id)"
                  :custom-label="key => languages.find(x => x.id == key).sv"
                  :show-labels="false"
                  @select="changed"
                />
              </b-col>
            </b-row>
            <b-button
              v-t="'components.herNorwayWithNotes.deleteherNorwayNotes'"
              @click="deleteNotes(item, hnindex)"
            />
          </b-list-group-item>
        </b-list-group>
        <b-row>
          <b-col sm="auto">
            <b-button
              v-t="'components.herNorwayWithNotes.newherNorwayNotes'"
              @click="addNotes(item)"
            />
          </b-col>
          <b-col sm="auto">
            <b-button
              v-t="'components.herNorwayWithNotes.delete'"
              @click="deleteHerNorwayWithNotes(index)"
            />
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>
    <b-spinner v-if="hersNorway.length == 0" />
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "HerNorwayWithNotes",
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      content: this.value,
      hersNorway: [],
      unusedHersNorway: [],
      usedToggles: [],
      languages: [],
      herLabels: {}
    };
  },
  watch: {
    value() {
      this.content = this.value;
    }
  },
  mounted: function () {
    this.content.forEach((item, index) => {
      this.usedToggles[index] = item.herNorway.id !== null;
    });
  },
  methods: {
    handleInput(e) {
      this.$emit("input", this.content);
    },
    changed() {
      this.$emit("change");
    },
    deleteHerNorwayWithNotes(index) {
      this.content.splice(index, 1);
      this.$emit("change");
    },
    addNotes(item) {
      item.herNorwayNotes.push({ notes: "", lang: { id: "sv-se" } });
      this.$emit("change");
    },
    deleteNotes(item, index) {
      item.herNorwayNotes.splice(index, 1);
      this.$emit("change");
    }
  },
  apollo: {
    hersNorway() {
      return {
        query: gql`
          query hersNorway {
            hersNorway {
              id
              name: locality
            }
            unusedHersNorway {
              id
              name: locality
            }
            languages: modernLanguages {
              id: language
              sv
            }
          }
        `,
        result(result) {
          if (result.stale) {
            // result is called again with stale = true
            return;
          }

          this.hersNorway = result.data.hersNorway.sort((a, b) => a - b);
          this.unusedHersNorway = result.data.unusedHersNorway.sort((a, b) => a - b);
          this.languages = result.data.languages.sort((a, b) =>
            a.sv.localeCompare(b.sv, "sv")
          );

          this.hersNorway.forEach(element => {
            this.herLabels[element.id] = element.name;
          });
        }
      };
    }
  }
};
</script>
